.form-renderer-wrapper {
    border: 2px dashed #ddd;
    padding: 20px;
    background-color: #f4faff;
}

.form-field-search-select-wrapper {
    text-align: center;
    margin-top: -22px;
}


.remove-button {
    position: absolute;
    right: 70px;
    top: 1px;
}

.form-elements-wrapper {
    position: relative;
}

.field-dragger {
    position: absolute;
    right: 31px;
    top: 8px;
    cursor: grab;
}