.action-header-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: end;
  flex-direction: row;
  margin-bottom: 25px;

  & .ant-select {
    margin-right: 15px;
  }
}
